<template>
<span>
    <div class="ol-dashboard__account">
      <form>
        <div class="ol-dashboard__account-item">
          <h4 class="ol-dashboard__container-title">Informasi Akun</h4>
          <p class="ol-dashboard__account">
            Kelola informasi profil Anda untuk mengontrol, melindungi
            dan mengamankan akun.
          </p>
          <Alert id="alert--profile" v-show="alert.message != null" :message="alert.message" :type="alert.type" />
          <Alert id="alert--progress-profile" v-if="progressProfile && progressProfile.score < 100" :message="progressProfile.message" type="info" />

          <div class="add-fix">
            <div class="ol-input-box ol-input-box--half">
              <label class="ol-input-label">Foto</label>
              <div class="ol-dashboard__account-upload">
                <input type="file" class="ol-input" id="file" v-on:change="saveFile" accept="image/*" />
                <span>
                  <small>+</small>
                  <i class="ol-fa fas fa-camera"></i>
                </span>
                <div>
                  <img id="blah" :src="user.picture ? user.picture : require('../../assets/images/user-profile.jpg') " alt="your image" />
                </div>
              </div>
            </div>
            <div class="ol-input-box ol-input-box--half">
              <!-- <button type="button" style="float:right" class="ol-btn">Perbarui Email</button> -->
            </div>
            <div class="ol-input-box ol-input-box--full">
              <label class="ol-input-label">Nama Lengkap</label>
              <input type="text" v-model="input.name" class="ol-input" />
            </div>
            <div class="ol-input-box ol-input-box--half">
              <label class="ol-input-label"
                >Telepon
                <!-- <a href="javascript:void(0)" style="float:right;color:#374B7A;" v-on:click="showPop('popupHP')" > <b><u>Ubah</u> </b></a > -->
              </label>
              <input type="number" v-model="input.phone" class="ol-input"  />
            </div>
            <div class="ol-input-box ol-input-box--half">
              <label class="ol-input-label"
                >Email
                <a href="javascript:void(0)" style="float:right;color:#374B7A;" v-on:click="showPop('popupMail')" > <b><u>Ubah</u> </b></a >
              </label>
              <input type="text" v-model="user.email" class="ol-input ol-input--disabled" disabled />
            </div>
            <div class="ol-input-box ol-input-box--half">
              <label class="ol-input-label">Jenis Kelamin</label>
              <div class="ol-select-box">
                <select v-model="input.gender" id="" class="ol-input ol-input-select" >
                  <option value="L">Laki-laki</option>
                  <option value="P">Perempuan</option>
                </select>
              </div>
            </div>
            <div class="ol-input-box ol-input-box--half">
              <label class="ol-input-label">Tanggal Lahir</label>
              <input type="date" v-model="input.birthday" class="ol-input" />
            </div>
          </div>
        </div>

        <div class="ol-dashboard__account-item">
          <h4 class="ol-dashboard__container-title">Media Sosial</h4>
          <div class="add-fix">
            <div class="ol-input-box ol-input-box--half">
              <label class="ol-input-label">Facebook</label>
              <input
                type="text"
                v-model="input.account_fb"
                class="ol-input"
              />
            </div>
            <div class="ol-input-box ol-input-box--half">
              <label class="ol-input-label">Instagram</label>
              <input
                type="text"
                v-model="input.account_ig"
                class="ol-input"
              />
            </div>
            <div class="ol-input-box ol-input-box--half">
              <label class="ol-input-label">Tiktok</label>
              <input
                type="text"
                v-model="input.account_tiktok"
                class="ol-input"
              />
            </div>
            <div class="ol-input-box ol-input-box--full">
              <button type="button" v-on:click="save()" class="ol-btn">
                Simpan
              </button>
            </div>
          </div>
        </div>

        <div class="ol-dashboard__account-item" style="display:none">
          <h4 class="ol-dashboard__container-title">Marketplace</h4>
          <div class="add-fix">
            <div class="ol-input-box ol-input-box--half">
              <label class="ol-input-label">Tokopedia</label>
              <input type="text" class="ol-input" disabled />
            </div>
            <div class="ol-input-box ol-input-box--half">
              <label class="ol-input-label">JD.ID</label>
              <input type="text" class="ol-input" disabled />
            </div>
            <div class="ol-input-box ol-input-box--half">
              <label class="ol-input-label">Shopee</label>
              <input type="text" class="ol-input" disabled />
            </div>
            
          </div>
        </div>
      </form>
    </div>
  
    <LoadingImg v-if="loading.profile" />
    <Popup title="Ubah Telepon" id="popupHP">
      <LoadingImg v-if="loading.hp" />
      <form>
        <Alert
          v-show="message.collection != null"
          :type="message.type"
          :collection="message.collection"
        />
        <div class="ol-input-box ol-input-box--half">
          <label class="ol-input-label">Telepon/No HP baru</label>
          <input
            type="number"
            placeholder="08176xxxx..."
            v-model="hp.phone"
            class="ol-input"
          />
        </div>
        <div class="ol-input-box ol-input-box--half">
          <label class="ol-input-label">&nbsp;</label>
          <button type="button" @click="sendWAOtp()" class="ol-btn">
            Kirim OTP(Whatsapp)
          </button>
        </div>

        <div class="ol-input-box ol-input-box--half">
          <label class="ol-input-label">Masukan Kode OTP</label>
          <input type="number" name="otp" v-model="hp.otp" class="ol-input" />
        </div>
        <div class="ol-input-box ol-input-box--full">
          <button type="button" @click="validWAOtp()" class="ol-btn">
            Simpan
          </button>
        </div>
      </form>
    </Popup>

    <Popup title="Ubah Email" id="popupMail">
      <LoadingImg v-if="loading.email" />
      <form>
        <Alert
          v-show="message.collection != null"
          :type="message.type"
          :collection="message.collection"
        />
        <div class="ol-input-box ol-input-box--half">
          <label class="ol-input-label">Email baru</label>
          <input type="email" v-model="mail.email" class="ol-input" />
        </div>
        <div class="ol-input-box ol-input-box--half">
          <label class="ol-input-label">&nbsp;</label>
          <button type="button" @click="sendMailOtp()" class="ol-btn">
            Kirim OTP
          </button>
        </div>

        <div class="ol-input-box ol-input-box--half">
          <label class="ol-input-label">Masukan Kode OTP</label>
          <input type="number" name="otp" v-model="mail.otp" class="ol-input" />
        </div>
        <div class="ol-input-box ol-input-box--half">
          <label class="ol-input-label">Masukan Kata Sandi</label>
          <Password
            v-model="mail.password"
            :toggle="true"
            :showStrengthMeter="false"
            :badge="false"
          />
        </div>
        <div class="ol-input-box ol-input-box--full">
          <button type="button" @click="saveMail()" class="ol-btn">
            Simpan
          </button>
        </div>
      </form>
    </Popup>
    </span>
</template>

<script>
import Popup from "@/components/popup/Popup.vue";
import Password from "vue-password-strength-meter";
import { mapGetters } from "vuex";

export default {
  name: "InfoAccount",
  components: {
    Popup,
    Password,
  },
  data() {
    return {
      input: {
        name: null,
        gender: null,
        birthday: null,
        phone: null,
        account_fb: null,
        account_ig: null,
        account_tiktok: null,
      },
      mail: {
        email: null,
        otp: null,
        password: null,
      },
      hp: {
        phone: null,
        otp: null,
      },
      loading: {
        profile: false,
        email: false,
        hp: false,
      },
      message: {
        collection: null,
        type: "info",
      },
      alert: {
        message: null,
        type: "info",
      },
      validate: {},
    };
  },
  computed: {
    ...mapGetters("auth", ["user", "progressProfile"]),
  },
  methods: {
    async save() {
      if (!this.input.name) {
        this.alert.message = "Nama Lengkap wajib dilengkapi";
        this.alert.type = "danger";
        this.scroll2Top();
      } else if (!this.input.gender) {
        this.alert.message = "Jenis Kelamin wajib dilengkapi";
        this.alert.type = "danger";
        this.scroll2Top();
      } else if (!this.input.birthday) {
        this.alert.message = "Tanggal Lahir wajib dilengkapi";
        this.alert.type = "danger";
        this.scroll2Top();
      } else {
        this.loading.profile = true;
        let isSuccess = await this.$store.dispatch("auth/saveUser", this.input);
        if (isSuccess) {
          this.alert.message = "Berhasil memperbarui profil";
          this.alert.type = "success";
          this.loading.profile = false;
        } else {
          this.alert.message =
            "Gagal memperbarui profil, coba beberapa saat lagi";
          this.alert.type = "danger";
          this.loading.profile = false;
        }
        this.scroll2Top();
      }
    },
    loadPhoto(e) {
      var o = new FileReader();
      (o.onload = function(e) {
        $("#blah").attr("src", e.target.result);
      }),
        o.readAsDataURL(e.target.files[0]);
    },
    async saveFile(e) {
      let files = e.target.files;
      if (files && files[0]) {
        let image = files[0];
        this.loading.profile = true;
        let isSuccess = await this.$store.dispatch("auth/savePhoto", image);
        if (isSuccess) {
          this.alert.message = "Berhasil memperbarui foto";
          this.alert.type = "success";
          this.loading.profile = false;
          this.loadPhoto(e);
        } else {
          this.alert.message =
            "Gagal memperbarui foto, coba beberapa saat lagi";
          this.alert.type = "danger";
          this.loading.profile = false;
        }
        this.scroll2Top();
      }
    },
    async sendMailOtp() {
      if (!this.mail.email) {
        this.message.collection = { email: ["Kolom Email wajib dilengkapi"] };
        this.message.type = "danger";
      } else {
        if (this.mail.email != this.user.email) {
          this.loading.email = true;
          let result = await this.$store.dispatch("auth/sendMail_OTP", {
            email: this.mail.email,
          });
          if (result.success) {
            this.message.collection = { otp: [result.data.message] };
            this.message.type = result.data.otp_send ? "success" : "warning";
            this.loading.email = false;
          } else {
            this.message.collection = result.data ? { otp: [result.data.message] } : { otp: ["Gagal mengirim OTP, coba beberapa saat lagi."] };
            this.message.type = "danger";
            this.loading.email = false;
          }
        } else {
          this.message.collection = {
            email: ["Email telah digunakan, periksa kembali email anda"],
          };
          this.message.type = "danger";
        }
      }
    },
    async saveMail() {
      if (!this.mail.email) {
        this.message.collection = { email: ["Kolom Email wajib dilengkapi"] };
        this.message.type = "danger";
      } else if (!this.mail.otp) {
        this.message.collection = { email: ["Kolom OTP wajib dilengkapi"] };
        this.message.type = "danger";
      } else if (!this.mail.password) {
        this.message.collection = {
          email: ["Kolom Kata sandi wajib dilengkapi"],
        };
        this.message.type = "danger";
      } else {
        this.loading.email = true;
        let result = await this.$store.dispatch("auth/saveMail", this.mail);
        if (result.success) {
          this.message.collection = { email: ["Berhasil memperbarui email"] };
          this.message.type = "success";
          this.loading.email = false;
          this.mail.email = null;
          this.mail.otp = null;
          this.mail.password = null;
        } else {
          this.message.collection = result.data
            ? result.data
            : { email: ["Gagal memperbarui email, coba beberapa saat lagi."] };
          this.message.type = "danger";
          this.loading.email = false;
        }
      }
    },
    async sendWAOtp() {
      if (!this.hp.phone) {
        this.message.collection = { email: ["Kolom Telepon wajib dilengkapi"] };
        this.message.type = "danger";
      } else {
        if (this.hp.phone != this.user.phone) {
          this.loading.hp = true;
          let result = await this.$store.dispatch("auth/sendWA_OTP", {
            phone: this.hp.phone,
          });
          if (result.success) {
            this.message.collection = { otp: [result.data.message] };
            this.message.type = result.data.otp_send ? "success" : "warning";
            this.loading.hp = false;
          } else {
            this.message.collection = result.data
              ? result.data
              : { otp: ["Gagal mengirim OTP, coba beberapa saat lagi."] };
            this.message.type = "danger";
            this.loading.hp = false;
          }
        } else {
          this.message.collection = {
            email: ["Telepon telah digunakan, periksa kembali Telepon anda"],
          };
          this.message.type = "danger";
        }
      }
    },
    async validWAOtp() {
      if (!this.hp.phone || !this.hp.otp) {
        this.message.collection = { otp: ["Lengkapi semua Kolom dibawah!"] };
        this.message.type = "danger";
      } else {
        this.loading.hp = true;
        let result = await this.$store.dispatch("auth/validWA_OTP", this.hp);
        if (result.success) {
          if (result.data.status_verification) {
            this.savePhone();
          } else {
            this.message.collection = { otp: [result.data.message] };
            this.message.type = result.data.status_verification
              ? "success"
              : "warning";
            this.loading.hp = false;
          }
        } else {
          this.message.collection = result.data.message
            ? { otp: [result.data.message] }
            : { otp: ["Gagal mengirim OTP, coba beberapa saat lagi."] };
          this.message.type = "danger";
          this.loading.hp = false;
        }
      }
    },
    async savePhone() {
      this.loading.hp = true;
      let isSuccess = await this.$store.dispatch("auth/saveUser", {
        phone: this.hp.phone,
      });
      if (isSuccess) {
        this.message.collection = { email: ["Berhasil memperbarui Telepon"] };
        this.message.type = "success";
        this.loading.hp = false;
        this.hp.phone = null;
        this.hp.otp = null;
      } else {
        this.message.collection = {
          email: ["Gagal memperbarui Telepon, coba beberapa saat lagi"],
        };
        this.message.type = "danger";
        this.loading.hp = false;
      }
    },
    showPop(id = null, show = true) {
      this.message.collection = null;
      show ? Popup.methods.show(id) : Popup.methods.hide(id);
      this.mail.email = null;
      this.mail.otp = null;
      this.mail.password = null;
      this.hp.phone = null;
      this.hp.otp = null;
    },
    scroll2Top() {
      $("html,body").animate({ scrollTop: 0 }, 300);
    },
  },
  async mounted() {
    // console.log(this.user);
    this.input.name = this.user.name;
    this.input.gender = this.user.gender ? this.user.gender.trim() : null;
    this.input.birthday = this.user.birthday;
    this.input.phone = this.user.phone;
    this.input.account_fb = this.user.account_fb;
    this.input.account_ig = this.user.account_ig;
    this.input.account_tiktok = this.user.account_tiktok;
    // await this.$store.dispatch("auth/fetchProgressProfile");
  },
};
</script>

<style></style>
